<template>
  <main>
    <p>尚无系统权限</p>
    <p>请联系管理员添加权限</p>
  </main>
</template>
<style lang="less" scoped>
main {
  width: 100%;
  height: 350px;
  background-image: url('../../assets/img/noAuthority.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px auto;
  color: #909399;
  text-align: center;
  padding-top: 280px;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  p {
    margin-top: 16px;
  }
}
</style>
