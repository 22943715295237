<script>
import { login, getUserInfo } from '@/apis/login'
export default {
  name: 'LoginPage',
  setup() {
    login()
    getUserInfo()
  }
}
</script>
